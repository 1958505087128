import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getSearchEngines = createAsyncThunk('SEARCH_ENGINES/getSearchEngines', async () => {
    return await Repository.get(`${apiUrl}/search_engine/read`);
});

export const createSearchEngine = createAsyncThunk('SEARCH_ENGINES/createSearchEngine', async (data) => {
    return await Repository.post(`${apiUrl}/search_engine/create`, data).then(() => {
        success('Created successfully!');
    }).catch(() => {
        error("Creating Search Engine failed, something went wrong!");
    });
});

export const updateSearchEngine = createAsyncThunk('SEARCH_ENGINES/updateSearchEngine', async (data) => {
    return await Repository.put(`${apiUrl}/search_engine/update`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});