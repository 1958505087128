import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getBlackLists = createAsyncThunk('BLACK_LIST/getBlackLists', async () => {
    return await Repository.get(`${apiUrl}/black-list/read`);
});

export const getBlackList = createAsyncThunk('BLACK_LIST/getBlackList', async (data) => {
    return await Repository.get(`${apiUrl}/black-list/read-by-id${serializeQuery(data)}`);
});

export const createBlackList = createAsyncThunk('BLACK_LIST/createBlackList', async (data) => {
    return await Repository.post(`${apiUrl}/black-list/create`, data).then(() => {
        success('Created successfully!');
    }).catch(() => {
        error("Creating Black List failed, something went wrong!");
    });
});

export const updateBlackList = createAsyncThunk('BLACK_LIST/updateBlackList', async (data) => {
    return await Repository.put(`${apiUrl}/black-list/update`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});

export const deleteBlackList = createAsyncThunk('BLACK_LIST/deleteBlackList', async (data) => {
    console.log(data);
    return await Repository.delete(`${apiUrl}/black-list/delete`, {
        data: data
    }).then(() => {
        success('Deleted successfully!');
    }).catch(() => {
        error("Delete failed, something went wrong!");
    });
});
