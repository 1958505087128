import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit';
import {persistReducer, persistStore} from "redux-persist";

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const persistConfig = {
    version: 1,
    key: 'in-use',
    storage,
    whitelist: ['auth', 'searchJobs'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);