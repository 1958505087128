import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getCountries = createAsyncThunk('COUNTRY/getCountries', async () => {
    return await Repository.get(`${apiUrl}/country/read-ordered-by-name`);
});

export const getCountry = createAsyncThunk('COUNTRY/getCountry', async (data) => {
    return await Repository.get(`${apiUrl}/country/read-by-id${serializeQuery(data)}`);
});

export const createCountry = createAsyncThunk('COUNTRY/createCountry', async (data) => {
    return await Repository.post(`${apiUrl}/country/create`, data).then(() => {
        success('Created successfully!');
    }).catch(() => {
        error("Create failed, something went wrong!");
    });
});

export const updateCountry = createAsyncThunk('COUNTRY/updateCountry', async (data) => {
    return await Repository.put(`${apiUrl}/country/update`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});

export const deleteCountry = createAsyncThunk('COUNTRY/deleteCountry', async (data) => {
    return await Repository.delete(`${apiUrl}/country/delete?id=${data.id}`, {
        data: {
            id: data.id
        }
    }).then(() => {
        success('Deleted successfully!');
    }).catch(() => {
        error("Delete failed, something went wrong!");
    });
});

export const updateSearchEngines = createAsyncThunk('COUNTRY/updateSearchEngines', async (data) => {
    return await Repository.put(`${apiUrl}/country/update-search-engines`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});

export const updateCountryGroups = createAsyncThunk('COUNTRY/updateCountryGroups', async (data) => {
    return await Repository.put(`${apiUrl}/country/update-groups`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});
