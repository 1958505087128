import React, {useEffect, useState} from "react";
import {Button, Col, Input, Modal, Progress, Row, Select, Upload} from "antd";

export default function AiSearch() {
    const [modalVisible, setModalVisible] = useState(false);
    const [hideReportResult, setHideReportResult] = useState(true);
    const [percent, setPercentage] = useState(0);
    const [report, setReport] = useState("");
    const [owner, setOwner] = useState("");
    const [goods, setGoods] = useState("");
    const [territories, setTerritories] = useState("dsa");
    const [fileList, setFileList] = useState([{
        uid: '-1', name: 'case.xsls', status: 'done', url: 'https://path.to.excel',
    },]);
    const [options, setOptions] = useState([]);
    const [allowed, setAllowed] = useState(['lumakras', 'lumykras', 'rolls', 'roll\'s', 'rolls rolls', 'veg-roll', 'j-rolls rollo', 'roll ice', 'cripsy rolls', 'bake rolls', 'rolleta', 'rollitos', 'let\'s roll', 'croll', 'ice s\'cream', 'roll', 'rollin\' bakery,', 'trolls bon roll', 'rollton', 'modifast', 'medifast', 'mediplast', 'meligast', 'medi pass', 'medis', 'depifast', 'alanex', 'elatrex', 'elanco', 'livery elements', 'leavly', 'lifely', 'horizon']);

    useEffect(() => {
        setData();
    }, []);

    const setData = async () => {
        options.push({label: "Afghanistan", value: "Afghanistan"});
        options.push({label: "Albania", value: "Albania"});
        options.push({label: "Algeria", value: "Algeria"});
        options.push({label: "American Samoa", value: "American Samoa"});
        options.push({label: "Andorra", value: "Andorra"});
        options.push({label: "Angola", value: "Angola"});
        options.push({label: "Anguilla", value: "Anguilla"});
        options.push({label: "Antigua and Barbuda", value: "Antigua and Barbuda"});
        options.push({label: "Argentina", value: "Argentina"});
        options.push({label: "Armenia", value: "Armenia"});
        options.push({label: "Australia", value: "Australia"});
        options.push({label: "Austria", value: "Austria"});
        options.push({label: "Azerbaijan", value: "Azerbaijan"});
        options.push({label: "Bahamas", value: "Bahamas"});
        options.push({label: "Bahrain", value: "Bahrain"});
        options.push({label: "Bangladesh", value: "Bangladesh"});
        options.push({label: "Belarus", value: "Belarus"});
        options.push({label: "Belgium", value: "Belgium"});
        options.push({label: "Belize", value: "Belize"});
        options.push({label: "Benin", value: "Benin"});
        options.push({label: "Bhutan", value: "Bhutan"});
        options.push({label: "Bolivia", value: "Bolivia"});
        options.push({label: "Bosnia", value: "Bosnia"});
        options.push({label: "Botswana", value: "Botswana"});
        options.push({label: "Brazil", value: "Brazil"});
        options.push({label: "Brunei", value: "Brunei"});
        options.push({label: "Bulgaria", value: "Bulgaria"});
        options.push({label: "Burkina Faso", value: "Burkina Faso"});
        options.push({label: "Burundi", value: "Burundi"});
        options.push({label: "Cambodia", value: "Cambodia"});
        options.push({label: "Cameroon", value: "Cameroon"});
        options.push({label: "Canada", value: "Canada"});
        options.push({label: "Central African Republic (the)", value: "Central African Republic (the)"});
        options.push({label: "Chad", value: "Chad"});
        options.push({label: "Chile", value: "Chile"});
        options.push({label: "Colombia", value: "Colombia"});
        options.push({label: "Congo (the)", value: "Congo (the)"});
        options.push({label: "Costa Rica", value: "Costa Rica"});
        options.push({label: "Côte d'Ivoire", value: "Côte d'Ivoire"});
        options.push({label: "Croatia", value: "Croatia"});
        options.push({label: "Cuba", value: "Cuba"});
        options.push({label: "Cyprus", value: "Cyprus"});
        options.push({label: "Czech Republic", value: "Czech Republic"});
        options.push({label: "Denmark", value: "Denmark"});
        options.push({label: "Djibouti", value: "Djibouti"});
        options.push({label: "Dominica", value: "Dominica"});
        options.push({label: "Dominican Republic (the)", value: "Dominican Republic (the)"});
        options.push({label: "Ecuador", value: "Ecuador"});
        options.push({label: "Egypt", value: "Egypt"});
        options.push({label: "El Salvador", value: "El Salvador"});
        options.push({label: "Estonia", value: "Estonia"});
        options.push({label: "Ethiopia", value: "Ethiopia"});
        options.push({label: "Fiji", value: "Fiji"});
        options.push({label: "Finland", value: "Finland"});
        options.push({label: "France", value: "France"});
        options.push({label: "French Polynesia", value: "French Polynesia"});
        options.push({label: "Gabon", value: "Gabon"});
        options.push({label: "Gambia (the)", value: "Gambia (the)"});
        options.push({label: "Georgia", value: "Georgia"});
        options.push({label: "Germany", value: "Germany"});
        options.push({label: "Ghana", value: "Ghana"});
        options.push({label: "Gibraltar", value: "Gibraltar"});
        options.push({label: "Greece", value: "Greece"});
        options.push({label: "Greenland", value: "Greenland"});
        options.push({label: "Guadeloupe", value: "Guadeloupe"});
        options.push({label: "Guatemala", value: "Guatemala"});
        options.push({label: "Guyana", value: "Guyana"});
        options.push({label: "Haiti", value: "Haiti"});
        options.push({label: "Honduras", value: "Honduras"});
        options.push({label: "Hong Kong", value: "Hong Kong"});
        options.push({label: "Hungary", value: "Hungary"});
        options.push({label: "Iceland", value: "Iceland"});
        options.push({label: "India", value: "India"});
        options.push({label: "Indonesia", value: "Indonesia"});
        options.push({label: "Iran", value: "Iran"});
        options.push({label: "Iraq", value: "Iraq"});
        options.push({label: "Ireland", value: "Ireland"});
        options.push({label: "Israel", value: "Israel"});
        options.push({label: "Italy", value: "Italy"});
        options.push({label: "Jamaica", value: "Jamaica"});
        options.push({label: "Japan", value: "Japan"});
        options.push({label: "Jordan", value: "Jordan"});
        options.push({label: "Kazakhstan", value: "Kazakhstan"});
        options.push({label: "Kenya", value: "Kenya"});
        options.push({label: "Kiribati", value: "Kiribati"});
        options.push({label: "Korea (the Republic of)", value: "Korea (the Republic of)"});
        options.push({label: "Kuwait", value: "Kuwait"});
        options.push({label: "Kyrgyzstan", value: "Kyrgyzstan"});
        options.push({label: "Latvia", value: "Latvia"});
        options.push({label: "Lebanon", value: "Lebanon"});
        options.push({label: "Lesotho", value: "Lesotho"});
        options.push({label: "Libya", value: "Libya"});
        options.push({label: "Liechtenstein", value: "Liechtenstein"});
        options.push({label: "Lithuania", value: "Lithuania"});
        options.push({label: "Luxembourg", value: "Luxembourg"});
        options.push({label: "Madagascar", value: "Madagascar"});
        options.push({label: "Malawi", value: "Malawi"});
        options.push({label: "Malaysia", value: "Malaysia"});
        options.push({label: "Maldives", value: "Maldives"});
        options.push({label: "Mali", value: "Mali"});
        options.push({label: "Malta", value: "Malta"});
        options.push({label: "Marshall Islands (the)", value: "Marshall Islands (the)"});
        options.push({label: "Mauritius", value: "Mauritius"});
        options.push({label: "Mexico", value: "Mexico"});
        options.push({label: "Micronesia (Federated States of)", value: "Micronesia (Federated States of)"});
        options.push({label: "Moldova (the Republic of)", value: "Moldova (the Republic of)"});
        options.push({label: "Mongolia", value: "Mongolia"});
        options.push({label: "Morocco", value: "Morocco"});
        options.push({label: "Mozambique", value: "Mozambique"});
        options.push({label: "Myanmar", value: "Myanmar"});
        options.push({label: "Namibia", value: "Namibia"});
        options.push({label: "Nauru", value: "Nauru"});
        options.push({label: "Nepal", value: "Nepal"});
        options.push({label: "Netherlands", value: "Netherlands"});
        options.push({label: "New Zealand", value: "New Zealand"});
        options.push({label: "Nicaragua", value: "Nicaragua"});
        options.push({label: "Niger (the)", value: "Niger (the)"});
        options.push({label: "Nigeria", value: "Nigeria"});
        options.push({label: "North Macedonia", value: "North Macedonia"});
        options.push({label: "Norway", value: "Norway"});
        options.push({label: "Oman", value: "Oman"});
        options.push({label: "Pakistan", value: "Pakistan"});
        options.push({label: "Palestine, State of", value: "Palestine, State of"});
        options.push({label: "Panama", value: "Panama"});
        options.push({label: "Papua New Guinea", value: "Papua New Guinea"});
        options.push({label: "Paraguay", value: "Paraguay"});
        options.push({label: "Peru", value: "Peru"});
        options.push({label: "Philippines (the)", value: "Philippines (the)"});
        options.push({label: "Poland", value: "Poland"});
        options.push({label: "Portugal", value: "Portugal"});
        options.push({label: "Puerto Rico", value: "Puerto Rico"});
        options.push({label: "Qatar", value: "Qatar"});
        options.push({label: "Romania", value: "Romania"});
        options.push({label: "Russia", value: "Russia"});
        options.push({label: "Rwanda", value: "Rwanda"});
        options.push({label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines"});
        options.push({label: "Samoa", value: "Samoa"});
        options.push({label: "San Marino", value: "San Marino"});
        options.push({label: "Sao Tome and Principe", value: "Sao Tome and Principe"});
        options.push({label: "Saudi Arabia", value: "Saudi Arabia"});
        options.push({label: "Senegal", value: "Senegal"});
        options.push({label: "Serbia", value: "Serbia"});
        options.push({label: "Seychelles", value: "Seychelles"});
        options.push({label: "Sierra Leone", value: "Sierra Leone"});
        options.push({label: "Singapore", value: "Singapore"});
        options.push({label: "Slovakia", value: "Slovakia"});
        options.push({label: "Slovenia", value: "Slovenia"});
        options.push({label: "Solomon Islands", value: "Solomon Islands"});
        options.push({label: "Somalia", value: "Somalia"});
        options.push({label: "South Africa", value: "South Africa"});
        options.push({label: "Spain", value: "Spain"});
        options.push({label: "Sri Lanka", value: "Sri Lanka"});
        options.push({label: "Suriname", value: "Suriname"});
        options.push({label: "Sweden", value: "Sweden"});
        options.push({label: "Switzerland", value: "Switzerland"});
        options.push({label: "Taiwan", value: "Taiwan"});
        options.push({label: "Tajikistan", value: "Tajikistan"});
        options.push({label: "Tanzania", value: "Tanzania"});
        options.push({label: "Thailand", value: "Thailand"});
        options.push({label: "Togo", value: "Togo"});
        options.push({label: "Tonga", value: "Tonga"});
        options.push({label: "Trinidad and Tobago", value: "Trinidad and Tobago"});
        options.push({label: "Tunisia", value: "Tunisia"});
        options.push({label: "Turkey", value: "Turkey"});
        options.push({label: "Turkmenistan", value: "Turkmenistan"});
        options.push({label: "Uganda", value: "Uganda"});
        options.push({label: "Ukraine", value: "Ukraine"});
        options.push({label: "United Arab Emirates", value: "United Arab Emirates"});
        options.push({label: "United Kingdom", value: "United Kingdom"});
        options.push({label: "United States", value: "United States"});
        options.push({label: "Uruguay", value: "Uruguay"});
        options.push({label: "Uzbekistan", value: "Uzbekistan"});
        options.push({label: "Venezuela", value: "Venezuela"});
        options.push({label: "Vietnam", value: "Vietnam"});
        options.push({label: "Zambia", value: "Zambia"});
        options.push({label: "Zimbabwe", value: "Zimbabwe"});

        setOptions(options);
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    const delay = ms => new Promise(res => setTimeout(res, ms));
    const getRandom = (max) => {
        return Math.floor(Math.random() * max)
    };

    const generate = async () => {
        let allOk = check();
        if (allOk) {
            setHideReportResult(true);
            setPercentage(0);
            setModalVisible(true);
            await load();
        } else {
            error();
        }

    }

    const error = () => {
        Modal.error({
            title: 'This is an error message', content: 'Please fill information properly',
        });
    };

    const check = () => {
        if (report === "" || owner === "" || territories === "" || goods === "") {
            return false;
        }

        if (allowed.includes(report.toLowerCase())) {
            return true;
        }
        return false;
    }

    async function load() {
        let add = 0;
        while (true) {
            let wait = getRandom(2000);
            if (wait < 1000) {
                wait = 1000;
            }
            await delay(wait);
            add = getAdd(add);
            setPercentage(add);
            if (add === 100) {
                break;
            }
        }

        await delay(1500);
        setModalVisible(false);
        setPercentage(0);
        printExcelPath();
        setHideReportResult(false);
    }

    let props = {
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188', onChange({file, fileList}) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        }, fileList: fileList, showUploadList: {
            showDownloadIcon: false, downloadIcon: 'Download', showRemoveIcon: false
        },
    };

    const printExcelPath = () => {
        let type = getFileName(report);
        console.log(type)
        let name = type + '-report.xls';
        console.log(name)

        let urlPath = 'https://productionchecker-s3-bucket.s3.eu-west-2.amazonaws.com/ai-search/' + name;

        setFileList([{
            uid: '1', name: name, status: 'done', url: urlPath,
        }]);
    }

    const getFileName = (name) => {
        switch (name.toLowerCase().trim()) {
            case 'lumakras':
            case 'lumykras':
                return 'LUMAKRAS';
            case 'rolls':
            case 'roll\'s':
            case 'rolls rolls':
            case 'veg-roll' :
            case 'j-rolls rollo':
            case 'roll ice':
            case 'cripsy rolls':
            case 'bake rolls':
            case 'rolleta':
            case 'rollitos':
            case 'let\'s roll':
            case 'croll':
            case 'ice s\'cream':
            case 'roll':
            case 'rollin\' bakery,':
            case 'trolls bon roll':
            case 'rollton':
                return 'ROLLS';
            case 'modifast':
            case 'medifast':
            case 'mediplast':
            case 'meligast':
            case 'medi pass':
            case 'medis':
            case 'depifast':
                return 'MODIFAST';
            case 'alanex':
            case 'elatrex':
            case 'elanco':
                return 'ALANEX';
            case 'livery elements':
            case 'leavly':
            case 'lifely':
                return 'LIVELY';
            case 'horizon':
                return 'HORIZON';
        }
    }

    const getAdd = (add) => {
        add += getRandom(30);
        if (add > 100) {
            add = 100;
        }
        return add;
    };


    return <Row gutter={[32, 32]} className="hp-mb-32">
        <Col flex="1" className="hp-overflow-hidden">
            <Row gutter={[32, 32]}>
                <Col span={24}>
                    <h1 className="mb-32">AI Search</h1>
                </Col>
            </Row>
            <Row gutter={[32, 32]}>
                <Col span={12}>
                    <span className="hp-font-weight-500 hp-mb-8 hp-d-block">Report:</span>
                    <Input
                        placeholder="Report"
                        size="middle"
                        value={report}
                        onChange={(e) => setReport(e.target.value)}
                    />
                </Col>
                <Col span={12}>
                    <span className="hp-font-weight-500 hp-mb-8 hp-d-block">Owner:</span>
                    <Input placeholder="Owner"
                           size="middle"
                           value={owner}
                           onChange={(e) => setOwner(e.target.value)}/>
                </Col>
                <Col span={12}>
                    <span className="hp-font-weight-500 hp-mb-8 hp-d-block">Goods And Services:</span>
                    <Input
                        placeholder="Goods And Services"
                        size="middle"
                        value={goods}
                        onChange={(e) => setGoods(e.target.value)}/>
                </Col>
                <Col span={12}>
                    <span className="hp-font-weight-500 hp-mb-8 hp-d-block">Territories:</span>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{width: '100%'}}
                        placeholder="Please select territories"
                        onChange={handleChange}
                        options={options}
                    />

                </Col>
                {/*<Col span={12}>*/}
                {/*    <span className="hp-font-weight-500 hp-mb-8 hp-d-block">Territories:</span>*/}
                {/*    <Input*/}
                {/*        placeholder="Territories"*/}
                {/*        size="middle"*/}
                {/*        value={territories}*/}
                {/*        onChange={(e) => setTerritories(e.target.value)}/>*/}

                {/*</Col>*/}
            </Row>
            <Row>
                <Col span={8}></Col>
                <Col span={8} className="hp-mt-24">
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            generate();
                        }}
                    >
                        Generate
                    </Button>
                </Col>
                <Col span={8}></Col>
            </Row>
            <Row className="hp-mt-24" hidden={hideReportResult}>
                <Col span={4} className="hp-mt-24">Click for your report</Col>
                <Col span={12}>
                    <Upload {...props}>
                    </Upload>
                </Col>
            </Row>
        </Col>
        <Modal
            visible={modalVisible}
            closeIcon={false}
            okButtonProps={{disabled: true}}
            cancelButtonProps={{disabled: true}}
            footer=""
            header=""
        >
            <Row>
                <Col span={8}></Col>
                <Col span={8} className="hp-text-center">
                    <div style={{marginBottom: 10}}>
                        <Progress type="circle" percent={percent}/>
                    </div>
                </Col>
                <Col span={8}></Col>
            </Row>
        </Modal>
    </Row>;
}
