import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getSearchJobs = createAsyncThunk('SEARCH_JOBS/getSearchJobs', async (data) => {
    return await Repository.get(`${apiUrl}/search_job/read${serializeQuery(data)}`);
});

export const getSearchJobsInProgress = createAsyncThunk('SEARCH_JOBS/getSearchJobsInProgress', async () => {
    return await Repository.get(`${apiUrl}/search_job/read/in-progress`);
});

export const createSearchJob = createAsyncThunk('SEARCH_JOBS/createSearchJob', async (data) => {
    return await Repository.post(`${apiUrl}/search_job/create`, data).then(() => {
        success('Search Job created successfully!');
    }).catch(() => {
        error("Create failed, something went wrong!");
    });
});

export const runJob = createAsyncThunk('SEARCH_JOBS/runJob', async (data) => {
    return await Repository.post(`${apiUrl}/search_job/run-job`, data);
});

export const updateSearchJob = createAsyncThunk('SEARCH_JOBS/updateSearchJob', async (data) => {
    return await Repository.put(`${apiUrl}/search_job/update`, data).then(() => {
        success('Updated successfully!');
    }).catch(() => {
        error("Update failed, something went wrong!");
    });
});

export const createSearchJobImage = createAsyncThunk('SEARCH_JOBS/updateSearchJob', async (data) => {
    return await Repository.post(`${apiUrl}/search_job/upload/new-image`, data);
});

export const sendJobStatus = createAsyncThunk('SEARCH_JOBS/sendJobStatus', async (data) => {
    return await Repository.post(`${apiUrl}/search_job/job-is-finished?search_job_id=${data}`);
})
