import { createSlice } from '@reduxjs/toolkit';
import {getBlackLists} from "./blackListActions";

const initialState = {
    blackList: [],
};

export const blackListReducer = createSlice({
    name: 'BLACK LIST',
    initialState,

    reducers: {
    },
    extraReducers: {
        [getBlackLists.pending]: (state) => {
            state.status = true;
        },
        [getBlackLists.fulfilled]: (state, { payload }) => {
            state.blackList = payload.data;
            state.status = false;
        },
        [getBlackLists.rejected]: (state) => {
            state.status = false;
        },
    },
});

export const {} = blackListReducer.actions;

export default blackListReducer.reducer;
