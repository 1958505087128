import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getLinkDetailsAdmin = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/link_details/read/admin/by-search-job${serializeQuery(data)}`);
});

export const getSearchJobStatus = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/export/search_job/read/status/${data}`);
});

export const getSearchJobByID = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.get(`${apiUrl}/search_job/read/${data}`);
});

export const setSearchJobStatus = createAsyncThunk('LINK_DETAILS/getLinkDetailsAdmin', async (data) => {
    return await Repository.put(`${apiUrl}/search_job/update-status`, data).then(() => {
        success('No valid results set!');
    }).catch(() => {
        error("Failed, something went wrong!");
    });
});

export const setDataForSending = createAsyncThunk('LINK_DETAILS/setDataForSending', async (data) => {
    return await Repository.put(`${apiUrl}/link_details/update/list-links-statuses`, data).then(() => {
        success('Set for Sending!');
    }).catch(() => {
        error("Sending failed, something went wrong!");
    });
});

export const addNewLink = createAsyncThunk('LINK_DETAILS/addNewLink', async (data) => {
    return await Repository.post(`${apiUrl}/link_details/create`, data);
});

export const generateResults = createAsyncThunk('LINK_DETAILS/generateResults', async (data) => {
    return await Repository.post(`${apiUrl}/link_details/generate/results`, data);
});

export const uploadLinkImage = createAsyncThunk('LINK_DETAILS/uploadScreenshot', async ({file, linkId}) => {
    const formData = new FormData();
    formData.append('file', file);
    return await Repository.post(`${apiUrl}/link_details/upload-screenshot?link_id=${linkId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
});
