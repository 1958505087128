import { createSlice } from '@reduxjs/toolkit';
import {getCountryGroups} from "./countryGroupActions";

const initialState = {
    groups: [],
};

export const countryGroupReducer = createSlice({
    name: 'COUNTRY GROUPS',
    initialState,

    reducers: {
    },
    extraReducers: {
        [getCountryGroups.pending]: (state) => {
            state.status = true;
        },
        [getCountryGroups.fulfilled]: (state, { payload }) => {
            state.groups = payload.data;
            state.status = false;
        },
        [getCountryGroups.rejected]: (state) => {
            state.status = false;
        },
    },
});

export const {} = countryGroupReducer.actions;

export default countryGroupReducer.reducer;
